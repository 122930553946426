import axios from 'axios';
/**
 * The Koddi Auth API.
 */
class KoddiAuthAPI {
    // Login with Auth0
    public postAuth0Token = async (
        member_group_id: number,
        id_token: string,
        access_token: string
    ): Promise<void> => {
        await axios.post(`${window.API_ROUTE}v1/sso/login`, {
            member_group_id,
            id_token,
            access_token,
        });
    };

    public resetPassword = async (email: string): Promise<boolean> => {
        await axios.put(`${window.API_ROUTE}v1/users/reset_password`, {
            email,
        });
        return true;
    };
}

export default KoddiAuthAPI;
