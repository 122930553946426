import {
    ButtonGroup as ChakraButtonGroup,
    ButtonGroupProps,
} from '@chakra-ui/react';
import React from 'react';
import { Colors, Spacing } from '../ChakraTheme';

type Props = {
    children: React.ReactNode;
    size?: 'sm' | 'md' | 'lg';
} & ButtonGroupProps;

export const CAttachedButtonGroup = ({
    children,
    size = 'sm',
    ...rest
}: Props): JSX.Element => {
    return (
        <ChakraButtonGroup
            size={size}
            isAttached
            variant="outline"
            bg={Colors.grayCool}
            borderRadius={Spacing.XS}
            w="fit-content"
            border={`1px solid ${Colors.gray}`}
            padding={Spacing.XXXS}
            sx={{
                button: {
                    _hover: { bg: Colors.grayLight },
                    borderRadius: Spacing.XS,
                },
            }}
            {...rest}
        >
            {children}
        </ChakraButtonGroup>
    );
};
