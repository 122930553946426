import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';

export const CFAIcon = ({
    icon,
    ...props
}: IconProps & {
    icon: IconDefinition;
}): JSX.Element => (
    /* @ts-ignore */
    <Icon {...props}>
        <FontAwesomeIcon icon={icon} />
    </Icon>
);
